import { useSurePlans } from 'flows/insurance/utils';
import { useEffect, useState } from 'react';
import { useUserData } from './use-profile';

/**
 * hook to retrieve sure plan information
 * @returns {data, loading, error}
 */
export const useInsuranceProviderData = () => {
  const [propertyAddres, setPropertyAddress] = useState(null);
  const result = useSurePlans(propertyAddres);
  const { user, loading: userLoading } = useUserData();

  useEffect(() => {
    if (user) {
      const { toAddress } = user.currentMove;
      setPropertyAddress({
        city: toAddress.city,
        postal: toAddress.postalCode,
        unit: toAddress.unit || '',
        region: toAddress.state,
        street_address: toAddress.street,
        country_code: 'US',
      });
    }
  }, [user, userLoading]);
  return result;
};
