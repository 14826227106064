import { useTracking } from 'react-tracking';
import { useUserAndInviteInfo } from './useUserAndInviteInfo';
import { TrackEventInput } from '@updater/ui-tracker';
import { useEffect } from 'react';

export const hasNonEmptyStringProp = (
  obj: Record<string, unknown>,
  key: string
) => {
  return obj.hasOwnProperty(key) && typeof obj[key] === 'string' && !!obj[key];
};

export const isRecord = (value: unknown): value is Record<string, unknown> => {
  return typeof value === 'object' && value !== null;
};

export const validAddress = (addr: unknown) => {
  if (isRecord(addr)) {
    return (
      hasNonEmptyStringProp(addr, 'street') &&
      hasNonEmptyStringProp(addr, 'state') &&
      hasNonEmptyStringProp(addr, 'city') &&
      hasNonEmptyStringProp(addr, 'postalCode')
    );
  }
  return false;
};

export const useIsMoveUPO = (
  requireToAddressOnInvite = false
): {
  loading: boolean;
  error?: Error;
  data?: {
    isUPO: boolean;
    source?: 'invite' | 'user';
  };
} => {
  const { data, loading, error } = useUserAndInviteInfo();
  const { trackEvent } = useTracking<TrackEventInput<unknown>>({
    domain: 'sign-up',
    object: 'upo_toAddress',
  });

  useEffect(() => {
    const addressIsInvalid = validAddress(
      data?.inviteByToken?.invite?.toAddress
    );
    const isUpo = data?.inviteByToken?.invite?.residentOnboardingEnabled;
    if (isUpo && !addressIsInvalid) {
      trackEvent({ verb: 'errored' });
    }
  }, [
    data?.inviteByToken?.invite?.residentOnboardingEnabled,
    data?.inviteByToken?.invite?.toAddress,
    trackEvent,
  ]);

  if (data?.inviteByToken?.invite?.residentOnboardingEnabled) {
    return {
      loading: false,
      error,
      data: {
        isUPO: requireToAddressOnInvite
          ? validAddress(data.inviteByToken.invite.toAddress)
          : true,
        source: 'invite',
      },
    };
  }

  if (data?.getUser?.user?.currentMove) {
    return {
      loading: false,
      error,
      data: {
        isUPO: data?.getUser?.user?.currentMove?.residentOnboardingEnabled,
        source: 'user',
      },
    };
  }

  if (data?.getUser && data?.inviteByToken) {
    return {
      loading: false,
      error,
      data: {
        isUPO: false,
      },
    };
  }

  return {
    loading,
    error,
  };
};
