import { AddressHomeSize } from 'types/generated/globalTypes';

export const homeSizeForDolly = (homeSize: AddressHomeSize) => {
  if (homeSize === AddressHomeSize.three_bed_plus) {
    return AddressHomeSize.four_bed_plus;
  }
  if (!homeSize) {
    return AddressHomeSize.two_bed;
  }
  return homeSize;
};
