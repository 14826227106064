import { useInviteToken } from 'flows/core/components/onboarding/common/hooks';
import { useUserAndInviteInfoQuery } from '@updater/consumer-graph';

/*
 * Because this query requires us forcing a default token if nothing is provided
 * we've centralized the call here.
 */
export const useUserAndInviteInfo = () => {
  const token = useInviteToken();
  return useUserAndInviteInfoQuery({
    variables: {
      // Currently a token must be provided so we provide a fake and searchable token
      token: token ?? 'no_token_consumer_app',
    },
    errorPolicy: 'all',
  });
};
