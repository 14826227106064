import { useQuery } from '@apollo/client';
import { TrackEventInput } from '@updater/ui-tracker';
import { useEffect } from 'react';
import { useTracking } from 'react-tracking';
import { dollyMove, dollyMoveVariables } from 'types/generated/dollyMove';
import { cleanAddress } from 'utils/addresses';
import { GetDollyEligibility } from 'utils/queries';
import { useGetUserInfoQuery } from './use-get-user-info-query';

export const useDollyEligibility = () => {
  const { trackEvent } = useTracking<TrackEventInput<unknown>>({
    domain: 'mys',
    object: 'dolly_eligibility',
    verb: 'tracked',
  });

  const {
    data: userData,
    error: userError,
    loading: userLoading,
  } = useGetUserInfoQuery();
  const result = useQuery<dollyMove, dollyMoveVariables>(GetDollyEligibility, {
    variables: {
      input: {
        moveId: userData?.getUser.user.currentMove.id,
        fromAddress: cleanAddress(
          userData?.getUser.user.currentMove.fromAddress
        ),
        toAddress: cleanAddress(userData?.getUser.user.currentMove.toAddress),
        moveDate: userData?.getUser.user.currentMove.date,
      },
    },
    skip: userLoading || !userData || !!userError,
  });

  useEffect(() => {
    if (result.data) {
      trackEvent({
        details: {
          eligible: result.data.dollyMove?.eligible,
        },
      });
    }
  }, [trackEvent, result]);

  return result;
};
