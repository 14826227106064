import { TrackEventInput } from '@updater/ui-tracker';
import {
  CardData,
  CardDataWithHeroExtras,
  InitiatePayload,
  PromoPayload,
  ROBInitiateSubtype,
} from 'flows/core/types/cards';
import { useIsMoveUPO } from 'hooks/useIsMoveUPO';
import { useEffect, useMemo } from 'react';
import { useTracking } from 'react-tracking';
import { ItemKinds } from 'types';
import { EssentialCards } from '../types/layouts';
import {
  makeEssentialsTabForwardMailCards,
  makeEssentialsTabInsuranceCards,
  makeEssentialsTabResidentOnboardingCards,
  makeEssentialsTabTVInternetCards,
  makeEssentialsTabUtilityCards,
  makeMovingTabDraftMoveYourStuffCards,
  makeMovingTabPackingSuppliesCards,
  makeTodayTabForwardMailCards,
  makeTodayTabInsuranceCards,
  makeTodayTabMoveYourStuffCards,
  makeTodayTabPackingSuppliesCards,
  makeTodayTabResidentOnboardingCards,
  makeTodayTabTVInternetCards,
  makeTodayTabUtilityCards,
} from '../utils/card-makers';
import {
  makeEssentialsTabHomeownersInsuranceCards,
  makeTodayTabHomeownersInsuranceCards,
} from '../utils/card-makers/homeowners-insurance';
import { useDataForCards } from './use-data-for-cards';
import { useDollyEligibility } from './use-dolly-eligibility';
import { useInsuranceProviderData } from './use-insurance-provider';
import { useUserData } from './use-profile';
import { useFeature } from '@growthbook/growthbook-react';
import { useCheckFlowStatus } from '../utils/card-makers/utils';

type TodayCardResults = {
  loading: boolean;
  error?: Error;
  cards?: CardDataWithHeroExtras[];
  heroCard?: CardDataWithHeroExtras;
};

type EssentialCardResults = {
  loading: boolean;
  error?: Error;
  toDoCards?: CardData[];
  completedCards?: CardData[];
  residentHasTasksRequiredForMoveIn?: boolean;
};

type MovingCardResults = {
  loading: boolean;
  error?: Error;
  cards?: CardData[];
  initiateCards?: InitiatePayload[];
  draftCards?: PromoPayload[];
};

const flowIsRequiredForMovein = (flow: { toDoCards: CardData[] }) => {
  return flow.toDoCards.some((card: CardData) => card.data.subtype === 'ROB');
};

export const useTodayTabCards = (): TodayCardResults => {
  const { loading, error, data } = useDataForCards();
  const { loading: dollyLoading, data: dollyData } = useDollyEligibility();
  const { data: plansData, error: plansError } = useInsuranceProviderData();

  return useMemo(() => {
    if (!loading && error) {
      return { loading, error };
    }
    if (!loading && !dollyLoading && data && (plansData || plansError)) {
      const cardArrays = [
        makeTodayTabPackingSuppliesCards(data),
        makeTodayTabForwardMailCards(data),
        makeTodayTabTVInternetCards(data),
        makeTodayTabMoveYourStuffCards(data, dollyData),
        makeTodayTabInsuranceCards(data, plansData),
        makeTodayTabUtilityCards(data),
        makeTodayTabResidentOnboardingCards(data),
        makeTodayTabHomeownersInsuranceCards(data),
      ];
      const [heroCard, ...otherCards] = cardArrays
        .flat()
        .filter((card) => Number(card.importanceScore) > 0)
        .sort((a, b) => Number(b.importanceScore) - Number(a.importanceScore))
        .slice(0, 4);
      return {
        loading: loading || dollyLoading,
        cards: otherCards,
        heroCard,
      };
    }
    return { loading: loading || dollyLoading || !plansData };
  }, [loading, error, dollyLoading, data, plansData, plansError, dollyData]);
};

export const useEssentialsTabCards = (): EssentialCardResults => {
  const { loading, error, data } = useDataForCards();
  const { on: insuranceDisabled } = useFeature('exclude_renters_insurance');
  const { value: customTasksEnabled } = useFeature('consumer_app_custom_tasks');
  const { trackEvent } = useTracking<TrackEventInput<unknown>>({
    domain: 'resident_onboarding',
    pageName: 'essentials_tab',
    object: 'eligibility',
    verb: 'tracked',
  });
  const { user } = useUserData();
  const { loading: isMoveUPOLoading, data: { isUPO } = { isUPO: false } } =
    useIsMoveUPO();

  const moveId = user?.currentMove?.id;
  const { loading: loadingFlowStatus, completedFlows } = useCheckFlowStatus({
    moveId,
  });

  const essentialCards = useMemo<EssentialCardResults>(() => {
    if (!loading && error) {
      return { loading, error };
    }
    if (!loading && !isMoveUPOLoading && !loadingFlowStatus && data) {
      const cardArrays = [
        makeEssentialsTabTVInternetCards({
          ...data,
          user,
          isUPO,
        }),
        makeEssentialsTabForwardMailCards({ ...data, completedFlows }),
        insuranceDisabled
          ? ({ toDoCards: [], completedCards: [] } as EssentialCards)
          : makeEssentialsTabInsuranceCards(data),
        makeEssentialsTabUtilityCards({ ...data, isUPO, completedFlows }),
        makeEssentialsTabResidentOnboardingCards(
          data,
          isUPO,
          customTasksEnabled
        ),
        makeEssentialsTabHomeownersInsuranceCards(data),
      ];

      // right now this just means they are resident onboarding
      const residentHasTasksRequiredForMoveIn = !!cardArrays.find((flow) =>
        flowIsRequiredForMovein(flow)
      );

      return {
        loading,
        toDoCards: cardArrays
          .map((flow) => flow.toDoCards)
          .flat()
          .sort(
            (a, b) => Number(b.importanceScore) - Number(a.importanceScore)
          ),
        completedCards: cardArrays
          .map((flow) => flow.completedCards)
          .flat()
          .sort(
            (a, b) => Number(b.importanceScore) - Number(a.importanceScore)
          ),
        residentHasTasksRequiredForMoveIn,
      };
    }
    return { loading };
  }, [
    loading,
    error,
    isMoveUPOLoading,
    loadingFlowStatus,
    data,
    user,
    isUPO,
    completedFlows,
    insuranceDisabled,
  ]);

  // track ROB cards
  // helper functions
  const hasSubIdentifier = (cardArray: CardData[], subidentifier: string) =>
    cardArray.some(({ identifier }) => identifier.includes(subidentifier));
  const utilityHasSubIdentifier = (
    cardArray: CardData[],
    utilityType:
      | ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_ELECTRIC
      | ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_GAS
      | ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_WATER
  ) =>
    cardArray.some(({ data: carrData }) =>
      (carrData as ROBInitiateSubtype).types?.includes(utilityType)
    );

  useEffect(() => {
    const {
      error: essentialCardsError,
      completedCards,
      toDoCards,
    } = essentialCards;
    if (!essentialCardsError && (completedCards || toDoCards)) {
      const allCards = [
        ...(completedCards as CardData[]),
        ...(toDoCards as CardData[]),
      ];
      const robCards = allCards.filter(
        (card: CardData) => card.data.subtype === 'ROB'
      );
      const insuranceCards = allCards.filter(
        (card: CardData) => card.data.subtype === 'SYI'
      );
      if (robCards.length > 0) {
        trackEvent({
          details: {
            pets: hasSubIdentifier(robCards, 'pets'),
            'proof-of-utilities': {
              proof_of_water: utilityHasSubIdentifier(
                robCards,
                ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_WATER
              ),
              proof_of_gas: utilityHasSubIdentifier(
                robCards,
                ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_GAS
              ),
              proof_of_electric: utilityHasSubIdentifier(
                robCards,
                ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_ELECTRIC
              ),
            },
            'move-costs': hasSubIdentifier(robCards, 'move-costs'),
            'proof-of-insurance': hasSubIdentifier(robCards, 'insurance'),
            'proof-of-insurance-rob-and-assurant':
              hasSubIdentifier(robCards, 'insurance') &&
              insuranceCards.length > 0,
            vehicles: hasSubIdentifier(robCards, 'vehicle'),
          },
        });
      }
    }
  }, [
    essentialCards,
    essentialCards.toDoCards,
    essentialCards.completedCards,
    trackEvent,
  ]);

  return essentialCards;
};

export const useMovingTabCards = (): MovingCardResults => {
  const { loading, error, data } = useDataForCards();

  const movingTabData = useMemo(() => {
    if (!loading && error) {
      return { loading, error };
    }
    if (!loading && data) {
      const cardArrays = [
        makeMovingTabDraftMoveYourStuffCards(data),
        makeMovingTabPackingSuppliesCards(),
      ];
      return {
        loading,
        cards: cardArrays
          .flat()
          .sort(
            (a, b) => Number(b.importanceScore) - Number(a.importanceScore)
          ),
      };
    }
    return { loading };
  }, [loading, error, data]);

  const initiateCards = useMemo(
    () =>
      (movingTabData?.cards || []).filter(
        ({ type }) => type === 'INITIATE'
      ) as InitiatePayload[],
    [movingTabData]
  );

  const draftCards = useMemo(
    () =>
      (movingTabData?.cards || []).filter(
        ({ type }) => type === 'PROMOTED'
      ) as PromoPayload[],
    [movingTabData]
  );

  return { ...movingTabData, initiateCards, draftCards };
};
