// TODO fix the location of this so it's not importing from the flows dir
import { TStates } from '@updater/ui-widgets';
import { homeSizeForDolly } from 'flows/core/utils/moving/dolly';
import { Address, RESTAddressType } from 'flows/forward-mail/types';
import {
  getUserInfo_getUser_user_currentMove_fromAddress,
  getUserInfo_getUser_user_currentMove_toAddress,
} from 'types/generated/getUserInfo';
import { AddressInput } from 'types/generated/globalTypes';

/**
 * @method formatAddressToString
 * @param {Address} address Address to be coverted in a string
 * @returns {String} formated address string
 */
export const formatAddressToString = (address: AddressInput): string =>
  `${address.street || ''} ${address.unit || ''} \n${address.city || ''}, ${
    address.state || ''
  } ${address.postalCode || ''}`;

/**
 * @method splitAddress
 * @param {Address} address Address to be coverted in a string
 * @returns {String[]} split up address array
 */
export const splitAddress = (address: AddressInput) => {
  return formatAddressToString(address)
    .split('\n')
    .map((str) => {
      return str;
    });
};

/**
 * @method formatRESTAddress
 * @param {RESTAddressType} RESTAddress Address formatted to REST api
 * @returns {Address} address formatted to gql standards
 */
export const formatRESTAddress = (RESTAddress: RESTAddressType): Address => ({
  street: RESTAddress.street,
  state: RESTAddress.state as TStates,
  city: RESTAddress.city,
  unit: RESTAddress.apt,
  postalCode: RESTAddress.zip,
  status: RESTAddress.status,
  forced: RESTAddress.status === 'forced',
});

/**
 * @method formatToRESTAddress
 * @param {Address} address formatted to gql standards
 * @returns {RESTAddressType} RESTAddress Address formatted to REST api
 */
export const formatToRESTAddress = (
  address: Address,
  status?: string
): RESTAddressType => ({
  street: address.street,
  state: address.state,
  city: address.city,
  apt: address.unit,
  zip: address.postalCode,
  // forced is an address status according to the backend
  // This check for valid is to prevent accidental overriding of valid addresses
  // TODO figure out if the valid check is necessary
  status:
    status?.toLowerCase() !== 'valid' && address?.forced
      ? 'forced'
      : address.status,
});

export const cleanAddress = (
  addr?:
    | getUserInfo_getUser_user_currentMove_fromAddress
    | getUserInfo_getUser_user_currentMove_toAddress
) => {
  if (addr) {
    return {
      city: addr.city,
      homeSize: homeSizeForDolly(addr.homeSize),
      postalCode: addr.postalCode,
      state: addr.state,
      street: addr.street,
    };
  }
  return undefined;
};
